import img1 from '../assets/img1.png'
import img2 from '../assets/img2.png'
import img3 from '../assets/img3.png'
import img4 from '../assets/img4.png'
import img5 from '../assets/img5.png'
import img6 from '../assets/img6.png'
import img7 from '../assets/img7.png'
import img8 from '../assets/img8.png'
import img9 from '../assets/img9.png'
import img10 from '../assets/img10.png'
import img11 from '../assets/img11.png'
import img12 from '../assets/img12.png'
import img13 from '../assets/img13.png'
import img14 from '../assets/img14.png'
import webapps from '../assets/webapps.png';
import java from '../assets/java.png';
import python from '../assets/python.png';

export const SliderProducts = [{
    name: 'Web Apps',
    detail: '',
    price: '10% Off',
    img: webapps
},
{
    name: 'Desktop Apps',
    detail: '',
    price: '10% Off',
    img: java
},
{
    name: 'Scripts',
    detail: '',
    price: '15% Off',
    img: python
}
]