import React from 'react';
import Logo from "../../assets/logo.png";
import {
    InboxIcon,
    PhoneIcon,
    LocationMarkerIcon,
    LoginIcon,
    UsersIcon,
    LinkIcon
} from "@heroicons/react/outline"
import css from "./footer.module.css";


export default function Footer() {
  return (
    <div className={css.cFooterWrapper}>
        <hr/>
        <div className={css.cFooter}>
            <div className={css.logo}>
                <img src={Logo} alt=""/>
                <span>AR Software</span>
            </div>
        

            <div className={css.block}>
                <div className={css.detail}>
                    <span>Contact US</span>
                    <span className={css.pngLine}>
                        <LocationMarkerIcon className={css.icon}/>
                        <span>San José, Costa Rica</span>
                    </span>
                </div>
            </div>
        </div>
        <div className={css.copyRight}>
                <span>Copyright ©2023 by AR Software, Inc.</span>
                <span>All rigths reserved.</span>
        </div>
    </div>
  )
}
